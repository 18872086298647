// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;

// Font-awesome
$fa-font-path: "~font-awesome/fonts";
$path-3rd: "../css/3rd/";
// Minhas Váriaveis

$bgColor2: #000;
$bgColor: #00a4bc;
$textColor2: $bgColor;
$bgColor4: $bgColor;

$textColor2: #ffffff;
$textColor2-hover: $bgColor;
$textColor: #000000;
$textColor-hover: $bgColor;

$titleTextColor: $bgColor;

$borderColor: #F5F0C9;


