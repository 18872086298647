.user-panel > .info > p {
  margin-bottom: 9px;
  position: absolute;
  top: 26px;
}

.form-group.modal {
  height: 1.8em;
  margin-top: 0.6em;
}

.preview {
  background-color: #FBFBFB;
  border: 1px #B3B3B3 solid;
  padding: 10px;
}

.new-cat-pai {

  font-size: 12px;
  margin-top: -15px;
  float: right;
}

td.img img{
  height:50px;
  width: 50px;
}

.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
  top: -10px;
}

.info-box-icon > img {
  max-width: 100%;
  border-radius: 50%;
  margin-top: -9px;
}

.products-list .product-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

span.record {
  float: right;
  margin-top: -30px;
  color: #daf1ed;
}

#messages {
  height: 20em;
}

.autocomplete-suggestions {
  border: 1px solid #999;
  background: #FFF;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background: #F0F0F0;
}

.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399FF;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

.count {
  text-align: center;
  font-size: 3em;
  color: #3c8dbc;
}
.count-label {
  text-align: right;
  margin-top: -25px;
}

.domain {
  font-size: 2em;
  color: #3c8dbc;
}

.direct-chat-primary .right > .direct-chat-text {
  background: #00a65a;
  border-color: #00a65a;
  color: #ffffff;
}